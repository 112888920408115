import React, {useEffect, useReducer, useState} from "react";
import {
    Button,
    Box, Slider, TextField,
    Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import '../setupPage.scss';
import {TemplateModel, TextTemplateModel} from "../SetupModels";
import {connect} from 'react-redux';
import {ApplicationState} from "../../ApplicationState";
import {
    updateTemplate,
    updateTemplateIconSize,
    updateTextTemplate,
    updateSurveySetType, updateTextTemplateActionCount,
    updateTextTemplateActionFontSize, updateTemplateActionSpacing
} from "../setupActions";
import {loadFontSizes, loadIconSizes, loadIconSpacings} from '../../lookups/lookupActions';
import * as lookupSelectors from '../../lookups/lookupSelectors';
import * as sharedSelectors from '../../shared/sharedSelectors'
import {KeyValueModel, OrganizationModel} from "../../shared/SharedModels";
import {SelectList} from "../../shared/SelectList";
import {IconSetType} from "../../lookups/LookupEnum";
import SliderInputBox from "../../shared/components/SliderInputBox";
import Icon from "../../shared/components/Icon";
import editBtnIcon from "../../svg/editbtn.svg";
import {TextSets} from "../../lookups/LookupsApiData";

type CustomizeIconsProps = {
    template: TemplateModel,
    textTemplate: TextTemplateModel,
    iconSizes: KeyValueModel[],
    iconSpacings: KeyValueModel[],
    staticIconSets: KeyValueModel[],
    animatedIconSets: KeyValueModel[],
    updateTemplate: typeof updateTemplate,
    updateTextTemplate: typeof updateTextTemplate,
    loadIconSizes: typeof loadIconSizes,
    loadIconSpacings: typeof loadIconSpacings,
    updateTemplateIconSize: typeof updateTemplateIconSize,
    updateTemplateActionSpacing: typeof updateTemplateActionSpacing,
    textSets: TextSets[],
    updateSurveySetType: typeof updateSurveySetType,
    surveySetType?: string,
    updateTextTemplateActionCount: typeof updateTextTemplateActionCount,
    fontSizes: KeyValueModel[],
    loadFontSizes: typeof loadFontSizes,
    updateTextTemplateActionFontSize: typeof updateTextTemplateActionFontSize
}


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: "",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    emailClients: {
        display: "flex",
    },
    active: {
        border: "2px solid #4B5A7A",
        boxShadow: "0 0 10px rgba(0,0,0,0.2);",
    },
    selectWidth: {
        width: "200px",
    },
    language: {
        display: "flex",
        alignItems: "center",
    },
    editBtn: {
        margin: "8px 0 4px 0",
        marginLeft: '3px',
    },
    colorBtns: {
        display: "flex",
    },
    customIcons: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    editText: {
        alignSelf: "flex-end",
        marginBottom: "6px"
    }
}));

type EditTextProps = {
    open: boolean,
    template: TemplateModel

    updateTemplate: typeof updateTemplate
    // actionInputField: any // TODO -> ADD TYPE
    // handleActionInput: any // TODO -> ADD TYPE
    handleChange: any // TODO -> ADD TYPE

    handleClose: () => void,
}

const EditTextPopup: React.FC<EditTextProps> = (props) => {

    const {  handleChange, handleClose } = props;
    //actionInputField, handleActionInput,
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">Edit Text</DialogTitle>
            <DialogContent>
                <DialogContentText className="dialog-content">
                    <TextField
                        name='action'
                        // value={actionInputField.actionInput}
                        value={"Test"}
                        id='actionInput'
                        label="Very Dissatisfied"
                        variant="outlined"
                        // onChange={handleActionInput}
                        onBlur={handleChange}
                    //>{actionInputField.actionInput}
                        >Options
                    </TextField>
                    <TextField
                        name='action'
                        // value={actionInputField.actionInput}
                        value={"Test"}
                        id='actionInput'
                        label="Dissatisfied"
                        variant="outlined"
                        // onChange={handleActionInput}
                        onBlur={handleChange}
                        //>{actionInputField.actionInput}
                    >Options
                    </TextField>
                    <TextField
                        name='action'
                        // value={actionInputField.actionInput}
                        value={"Test"}
                        id='actionInput'
                        label="Neutral"
                        variant="outlined"
                        // onChange={handleActionInput}
                        onBlur={handleChange}
                        //>{actionInputField.actionInput}
                    >Options
                    </TextField>
                    <TextField
                        name='action'
                        // value={actionInputField.actionInput}
                        value={"Test"}
                        id='actionInput'
                        label="Satisfied"
                        variant="outlined"
                        // onChange={handleActionInput}
                        onBlur={handleChange}
                        //>{actionInputField.actionInput}
                    >Options
                    </TextField>
                    <TextField
                        name='action'
                        // value={actionInputField.actionInput}
                        value={"Test"}
                        id='actionInput'
                        label="Very Satisfied"
                        variant="outlined"
                        // onChange={handleActionInput}
                        onBlur={handleChange}
                        //>{actionInputField.actionInput}
                    >Options
                    </TextField>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button color="secondary" onClick={handleClose}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
}

const CustomizeIcons = (props: CustomizeIconsProps) => {
    const classes = useStyles();
    const {
        template,
        textTemplate,
        updateTemplate,
        updateTextTemplate,
        iconSizes,
        iconSpacings,
        updateSurveySetType,
        surveySetType,
        updateTextTemplateActionCount,
        loadFontSizes,
        fontSizes,
        updateTextTemplateActionFontSize,
        updateTemplateActionSpacing
    } = props;

    const iconTypes: KeyValueModel[] = [
        {key: 1, value: 'Images'},
        {key: 2, value: 'Text Only'}
    ]
    const iconNumber: KeyValueModel[] = [
        {key: 1, value: 3},
        {key: 2, value: 2},
        {key: 3, value: 5},
    ]

    const [editTextPopupOpen, setEditTextPopupOpen] = useState<boolean | undefined>();

    const editTextClickHandler = () => {
        setEditTextPopupOpen(true);
    }

    const closeEditTextPopupHandler = () => {
        setEditTextPopupOpen(false);
    };

    const actionCount = template.actionCount;
    const actionCountTextTemplate = textTemplate.actionCount;

    useEffect(() => {
        if (!iconSizes.length) props.loadIconSizes();
        if (!iconSpacings.length) props.loadIconSpacings();
        if (!fontSizes.length) loadFontSizes();
    }, []);

    const handleChange = (event: React.ChangeEvent<{ id?: string, name?: string; value: any }>) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'iconType') {
            if(value === "Images") {
                updateSurveySetType(1)
            }
            else if (value === "Text Only") {
                updateSurveySetType(3)
            }
        }

        if (name === 'actionCount' && surveySetType === 'Images') {
            // filter the icon sets based on the type and value
            let iconSet = template.iconSet;
            if (value !== template.actionCount) {
                const currentSet = props.staticIconSets.find(set => set.key === template.iconSet);
                if (currentSet && currentSet.value.type === 1) {
                    switch (value) {
                        case 5:
                            iconSet = props.staticIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied)[0].key;
                            break;
                        case 3:
                            iconSet = props.staticIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation)[0].key;
                            break;
                        case 2:
                            iconSet = props.staticIconSets.filter((set: KeyValueModel) => set.value.verysatisfied || set.value.verydissatisfied)[0].key;
                            break;
                    }
                } else {
                    switch(value) {
                        case 5:
                            iconSet = props.animatedIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied)[0].key;
                            break;
                        case 3:
                            iconSet = props.animatedIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation)[0].key;
                            break;
                        case 2:
                            iconSet = props.animatedIconSets.filter((set: KeyValueModel) => set.value.verysatisfied || set.value.verydissatisfied)[0].key;
                            break;
                    }
                }
            }

            updateTemplate({
                ...template,
                iconSet,
                [name]: value,
            })
        } else if (name === 'actionCount' && surveySetType === 'Text Only') {
            let textSet: any = textTemplate.surveySetId;

            if (value !== textTemplate.actionCount) {
                const currentSet = props.textSets.find(set => set.id === textTemplate.surveySetId);

                if(currentSet) {
                    switch (value) {
                        case 5:
                            textSet = props.textSets.find(set => set?.actionCount === 5)?.id;
                            break;
                        case 3:
                            textSet = props.textSets.find(set => set?.actionCount === 3)?.id;
                            break;
                        case 2:
                            textSet = props.textSets.find(set => set?.actionCount === 2)?.id;
                            break;
                    }
                }
            }

            updateTextTemplateActionCount({
                surveySetId: textSet,
                [name]: value
            })
        }
        return
    }

    const handleActionFontSizeChange = (event: React.ChangeEvent<{ name?: string; value: any}>) => {
        const fontSizeId = fontSizes.find((font) => font.value === event.target.value)?.key;

        updateTextTemplateActionFontSize(fontSizeId)
    }

    const handleActionSpacingChange = (value: number) => {
        const actionSpacingId = iconSpacings.find(spacing => spacing.value === value)?.key;

        updateTemplateActionSpacing({value, id: actionSpacingId})
    }

    return (
        <>
            <Box style={{flex: "1", alignItems: "stretch"}} className={"setup-content-block-right"}>
                <Box style={{height: "100%"}} className='customize content-block'>
                    <Box className={classes.customIcons}>
                        <Box>
                            <Typography>Template Type</Typography>
                            <SelectList
                                id="iconType"
                                name="iconType"
                                className={`${classes.selectWidth} select-holder`}
                                value={surveySetType}
                                onChange={handleChange}
                                items={iconTypes}
                            />
                        </Box>
                        <Box>
                            <Typography>Number of Actions</Typography>
                            <SelectList
                                id="actionCount"
                                name="actionCount"
                                className={`${classes.selectWidth} select-holder`}
                                value={surveySetType === 'Images' ? actionCount : textTemplate.actionCount}
                                onChange={handleChange}
                                items={iconNumber}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.customIcons}>
                        {surveySetType === "Images" ?
                        <Box paddingLeft={"0.5rem"}>
                            <SliderInputBox
                                name={"iconSize"}
                                label={"Icon Size"}
                                step={null}
                                defaultValue={template.iconSize}
                                min={20}
                                max={80}
                                marks={iconSizes.map(size => ({value: size.value,}))}
                                onChange={(value) => { props.updateTemplateIconSize(value)}}
                            />
                        </Box> :
                            <Box style={{display: "flex", justifyContent: "space-evenly"}}>

                                {fontSizes &&
                                    <Box>
                                        <Typography>Size</Typography>
                                        <SelectList id="fontSize" name="size" className="select-holder"
                                                    value={textTemplate.actionFontSize ? textTemplate.actionFontSize : 12} onChange={handleActionFontSizeChange} items={fontSizes}
                                        />
                                    </Box>
                                }
                            </Box>
                        }
                        <Box paddingLeft={"0.5rem"}>
                            <SliderInputBox
                                name={"iconSpacing"}
                                label={"Action Spacing"}
                                step={null}
                                defaultValue={template.iconSpacing}
                                min={20}
                                max={100}
                                marks={iconSpacings.map(spacing => ({value: spacing.value}))}
                                onChange={handleActionSpacingChange}
                            />
                        </Box>
                    </Box>
                </Box>

                { editTextPopupOpen && <EditTextPopup
                    handleClose={closeEditTextPopupHandler}
                    open={editTextPopupOpen}
                    handleChange={handleChange}
                    template={template}
                    updateTemplate={updateTemplate}
                ></EditTextPopup> }
            </Box>
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        actions: lookupSelectors.actionTypesSelector(state),
        staticIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Static),
        animatedIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Animated),
        iconSizes: lookupSelectors.iconSizeTypesSelector(state),
        iconSpacings: lookupSelectors.iconSpacingTypesSelector(state),
        textSets: lookupSelectors.textSetsSelector(state),
        fontSizes: lookupSelectors.fontSizeTypesSelector(state)
    }
}

const mapDispatchToProps = {
    updateTemplate,
    updateTextTemplate,
    loadIconSizes,
    loadIconSpacings,
    updateTemplateIconSize,
    updateTemplateActionSpacing,
    updateSurveySetType,
    updateTextTemplateActionCount,
    updateTextTemplateActionFontSize
};

const CustomizeIconsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizeIcons);

export default CustomizeIconsContainer;
