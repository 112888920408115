import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import '../setupPage.scss';
import {TemplateModel, TextTemplateModel} from "../SetupModels";
import {TemplateColorTypes} from "../setupEnums";
import {connect} from 'react-redux';
import {ApplicationState} from "../../ApplicationState";
import {
    updateTemplate,
    updateTemplateTitleSpacing,
    updateTextTemplate, updateTextTemplateFontSize, updateTextTemplateTitleColour,
    updateTextTemplateFontFamily, updateTextTemplateTitle,
    updateTextTemplateLanguage
} from "../setupActions";
import {
    loadLanguages,
    loadTemplateActions,
    loadFontSizes,
    loadTitleSpacings,
    loadFontFamilies,
    loadColorPalette,
    loadTextSets
} from '../../lookups/lookupActions';
import * as lookupSelectors from '../../lookups/lookupSelectors';
import * as sharedSelectors from '../../shared/sharedSelectors'
import {ActionKeyValueModel, KeyValueModel} from "../../shared/SharedModels";
import {SelectList} from "../../shared/SelectList";
import CustomRadioButton from "../../shared/components/CustomRadioButton";
import editBtnIcon from "../../svg/editbtn.svg";
import Icon from "../../shared/components/Icon";
import {useStepperDispatch} from "./SetupStepper";
import SliderInputBox from "../../shared/components/SliderInputBox";
import {getTextTemplate} from "../../api";


type CustomizePanelProps = {
    template: TemplateModel,
    templateLanguages: KeyValueModel[],
    actions: ActionKeyValueModel[],
    fontSizes: KeyValueModel[],
    titleSpacings: KeyValueModel[],
    fontFamilies: KeyValueModel[],
    colorPalette: KeyValueModel[],

    updateTemplate: typeof updateTemplate,
    updateTemplateTitleSpacing: typeof updateTemplateTitleSpacing,
    loadLanguages: typeof loadLanguages,
    loadTemplateActions: typeof loadTemplateActions,
    loadFontSizes: typeof loadFontSizes,
    loadTitleSpacings: typeof loadTitleSpacings,
    loadFontFamilies: typeof loadFontFamilies,
    loadColorPalette: typeof loadColorPalette,
    updateTextTemplate: typeof updateTextTemplate,
    textTemplate: TextTemplateModel,
    surveySetType?: string,
    updateTextTemplateTitleColour: typeof updateTextTemplateTitleColour,
    updateTextTemplateFontSize: typeof updateTextTemplateFontSize,
    updateTextTemplateFontFamily: typeof updateTextTemplateFontFamily,
    updateTextTemplateTitle: typeof updateTextTemplateTitle,
    updateTextTemplateLanguage: typeof updateTextTemplateLanguage,
    loadTextSets: typeof loadTextSets
}

type CircleProps = {
    color: TemplateColorTypes,
    active: boolean,
};

type HexCircleProps = {
    color: string,
};

type EditCtaProps = {
    open: boolean,
    template: TemplateModel

    updateTemplate: typeof updateTemplate
    actionInputField: any // TODO -> ADD TYPE
    handleActionInput: any // TODO -> ADD TYPE
    handleChange: any // TODO -> ADD TYPE

    handleClose: () => void,
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: "",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    emailClients: {
        display: "flex",
    },
    circle: {
        borderRadius: "50%",
        marginRight: "4px",
        height: "25px",
        minWidth: "25px",
        display: "inline-block",
    },
    active: {
        border: "2px solid #4B5A7A",
        boxShadow: "0 0 10px rgba(0,0,0,0.2);",
    },
    selectWidth: {
        width: "100%",
    },
    language: {
        display: "flex",
        alignItems: "center",
    },
    infoIcon: {
        paddingLeft: "1rem",
        color: "#3B76EF"
    },
    ctaGroup: {
        display: "flex",
    },
    editBtn: {
        margin: "8px 0 4px 0",
        marginLeft: '3px',
    },
    colorBtns: {
        display: "flex",
        flexDirection: "column",
    },
    hexInputGroup: {
        display: "flex",
        flexDirection: "row",
        marginTop: "8px",
        marginBottom: "none",
        alignItems: "center",
    },
    hexInput: {
        marginRight: "10px",
    },
    customIcons: {
        display: "flex",
        flexDirection: "column",
    },
    smlSelect: {
        paddingLeft: '1rem'
    }
}));

const Circle = (props: CircleProps) => {
    const classes = useStyles();

    return (
        <div className={`${classes.circle} ${props.active ? classes.active : ''}`}
             style={{backgroundColor: props.color}}/>
    )
}

const HexCircle = (props: HexCircleProps) => {
    const classes = useStyles();

    return (
        <div className={`${classes.circle}`}
             style={{backgroundColor: props.color}}/>
    )
}

const EditCtaPopup: React.FC<EditCtaProps> = (props) => {
    const {actionInputField, handleActionInput, handleChange, handleClose} = props

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">Edit Call to Action</DialogTitle>
            <DialogContent>
                <DialogContentText className="dialog-content">
                    <TextField
                        name='action'
                        value={actionInputField.actionInput}
                        id='actionInput'
                        label="Call to Action"
                        variant="outlined"
                        onChange={handleActionInput}
                        onBlur={handleChange}
                    >{actionInputField.actionInput}
                    </TextField>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button color="secondary" onClick={handleClose}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
};

const CustomizePanel = (props: CustomizePanelProps) => {
    const classes = useStyles();

    const {
        template,
        loadLanguages,
        loadTemplateActions,
        loadFontSizes,
        loadTitleSpacings,
        loadFontFamilies,
        loadColorPalette,
        updateTemplate,
        templateLanguages,
        actions,
        fontSizes,
        titleSpacings,
        fontFamilies,
        colorPalette,
        updateTemplateTitleSpacing,
        textTemplate,
        updateTextTemplate,
        surveySetType,
        updateTextTemplateTitleColour,
        updateTextTemplateFontSize,
        updateTextTemplateFontFamily,
        updateTextTemplateTitle,
        updateTextTemplateLanguage,
        loadTextSets
    } = props;

    useEffect(() => {
        if (!templateLanguages.length) loadLanguages();
        if (!actions.length) loadTemplateActions();
        if (!fontSizes.length) loadFontSizes();
        if (!titleSpacings.length) loadTitleSpacings();
        if (!fontFamilies.length) loadFontFamilies();
        if (!colorPalette.length) loadColorPalette();
    }, []);

    const [editCtaPopupOpen, setEditCtaPopupOpen] = useState<boolean | undefined>();
    const [hexInputIsValid, setHexInputIsValid] = useState(true);

    const editCtaClickHandler = () => {
        setEditCtaPopupOpen(true);
    }

    const closeEditCtaPopupHandler = () => {
        setEditCtaPopupOpen(false);
    };

    const handleChange = async (event: React.ChangeEvent<{ id?: string, name?: string; value: any }>) => {
        setHexInputIsValid(true);

        if (actions.length && event.target.name === 'lang') {
            let defaultActions = actions.filter(action => action.lang === event.target.value);
            const [defaultAction] = defaultActions;
            let defaultSelectValue = defaultAction.value;

            updateTemplate({
                ...template,
                ['action'!]: defaultSelectValue,
                [event.target.name!]: event.target.value,
            });

            const languageId = templateLanguages.find(language => language.value === event.target.value)?.key;

            updateTextTemplateLanguage(languageId)
            updateTextTemplateTitle(defaultSelectValue)
            loadTextSets(languageId)

        } else {
            if (event.target.id === 'hexInput') {
                // Check if the hex input is valid
                if (event.target.value.match(/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/) && event.target.value.length > 3) {
                    // Check if the hex input is different from the current color
                    // Do not combine these checks into one if statement as it will set the input field to invalid if the values are the same
                    if (template.color !== event.target.value) {
                        updateTemplate({
                            ...template,
                            [event.target.name!]: event.target.value,
                        });

                        updateTextTemplateTitleColour(event.target.value)
                    }

                } else {
                    setHexInputIsValid(false)
                    window.alert('Please enter a valid hexadecimal color code e.g. #3498db')
                }
            } else {
                if (event.target.id === 'actionInput') {
                    updateTemplate({
                        ...template,
                        [event.target.name!]: event.target.value,
                    });

                    updateTextTemplateTitle(event.target.value)

                    loadTemplateActions();
                } else {
                    updateTemplate({
                        ...template,
                        [event.target.name!]: event.target.value,
                    });

                    switch (event.target.name) {
                        case "color":
                            updateTextTemplateTitleColour(event.target.value)
                            break;
                        case "size":
                            const fontSizeId = fontSizes.find(size => size.value === event.target.value)?.key;
                            updateTextTemplateFontSize(fontSizeId)
                            break;
                        case "font":
                            const fontFamilyId = fontFamilies.find(font => font.value === event.target.value)?.key;
                            updateTextTemplateFontFamily(fontFamilyId)
                            break;
                        case "action":
                            updateTextTemplateTitle(event.target.value)
                            break;
                        default:
                            return;
                    }
                }
            }
        }

        if (event.target.name === 'color') {
            setInputFields(initialInputFields => {
                return {
                    ...initialInputFields,
                    ['hexInput'!]: event.target.value,
                }
            });
        }

        if (event.target.name === 'action') {
            setActionInputField(initialActionInputFields => {
                return {
                    ...initialActionInputFields,
                    ['actionInput'!]: event.target.value,
                }
            });
        }
    }

    const initialInputFields = {
        hexInput: template.color,
    }

    const [inputFields, setInputFields] = useState(initialInputFields)


    const handleInput = (event: React.ChangeEvent<{ id?: string; value: any }>) => {
        if (event.target.value.length > 0 && event.target.value.length < 8) {
            setInputFields(prevState => {
                return {
                    ...prevState,
                    [event.target.id!]: event.target.value,
                }
            });
        }
    };

    const initialActionInputFields = {
        actionInput: template.action,
    }
    const [actionInputField, setActionInputField] = useState(initialActionInputFields)

    const handleActionInput = (event: React.ChangeEvent<{ id?: string; value: any }>) => {
        setActionInputField(prevState => {
            return {
                ...prevState,
                [event.target.id!]: event.target.value,
            }
        });
    };

    const handleCTASpacingChange = (value: number) => {
        const spacingId = titleSpacings.find(spacing => spacing.value === value)?.key;
        updateTemplateTitleSpacing({value, id: spacingId});
    }

    return (
        <>
            <Box className={"setup-content-block"}>
                <Box className='customize setup-content-block-left'>
                    <Box className='customize' style={{width: "100%"}}>
                        {actions.length &&
                            <Box>
                                <Typography>Call to Action</Typography>
                                <div className={`${classes.ctaGroup}`}>
                                    <FormControl variant="outlined" margin="dense">
                                        <Tooltip
                                            title={<p style={{
                                                fontSize: "0.8rem",
                                                lineHeight: "125%",
                                                textAlign: "center",
                                            }}>{template.action}</p>}
                                            placement="right-end"
                                            arrow>
                                            <Select
                                                id="action"
                                                name="action"
                                                value={template.action}
                                                displayEmpty
                                                className={`select-holder`}
                                                style={{width: "400px"}}
                                                onChange={handleChange}
                                            >
                                                {actions && actions.filter(action => action.lang === template.lang || action.key === 3).map(i =>
                                                    <MenuItem key={`lang_${i.key}`} value={i.value}>{i.value ? i.value :
                                                        <i>_None_</i>}</MenuItem>)}
                                            </Select>
                                        </Tooltip>
                                    </FormControl>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<Icon src={editBtnIcon}></Icon>}
                                        className={`${classes.editBtn}`}
                                        onClick={editCtaClickHandler}
                                        style={{backgroundColor: "#fff"}}>
                                        Edit
                                    </Button>
                                </div>
                            </Box>
                        }
                        {fontSizes &&
                            <Box className={classes.smlSelect}>
                                <Typography>Size</Typography>
                                <SelectList id="fontSize" name="size" className="select-holder"
                                            value={template.size} onChange={handleChange} items={fontSizes}
                                />
                            </Box>
                        }
                        {fontFamilies &&
                            <Box className={classes.smlSelect}>
                                <Typography>Font</Typography>
                                <SelectList id="fontFamily" name="font"
                                            className={`${classes.selectWidth} select-holder`}
                                            value={template.font} onChange={handleChange} items={fontFamilies}
                                            useValueAsFontFamily={true}
                                />
                            </Box>
                        }
                    </Box>
                    <Box className='customize'>
                        {templateLanguages &&
                            <Box>
                                <Typography>Language</Typography>
                                <div className={`${classes.language}`}>
                                    <SelectList id="lang" name="lang" className={`${classes.selectWidth} select-holder`}
                                                value={template.lang} onChange={handleChange} items={templateLanguages}
                                    />
                                    <Tooltip
                                        title={<p
                                            style={{
                                                fontSize: "0.8rem",
                                                lineHeight: "125%",
                                                textAlign: "center"
                                            }}>Changing the Language here will not only change the Language of the
                                            Call to Action, but will also change the Language of the
                                            Customer Feedback Form</p>}
                                        placement="right-end"
                                        arrow>
                                        <InfoIcon className={`${classes.infoIcon}`}/>
                                    </Tooltip>
                                </div>
                            </Box>
                        }
                        <Box>
                            <SliderInputBox
                                name={"ctaDistance"}
                                label={"CTA Distance"}
                                step={null}
                                defaultValue={template.titleSpacing}
                                onChange={handleCTASpacingChange}
                                min={8}
                                max={72}
                                marks={titleSpacings ? titleSpacings.map((spacing) => ({value: spacing.value})) : false}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box style={{marginLeft: "50px"}} className={"customize"}>
                    {colorPalette.length &&
                        <Box className="colorPalette-container">
                            <Typography>Color</Typography>
                            <div className={`${classes.colorBtns}`}>
                                <Box className="colorPalette" style={{width: "52%", minWidth: "242px"}}>
                                    {colorPalette.map(color =>
                                        <CustomRadioButton name="color" key={color.key} className="color-btn"
                                                           value={color.value}
                                                           selected={template.color}
                                                           handleChange={handleChange}>
                                            <Circle color={color.value} active={template.color === color.value}/>
                                        </CustomRadioButton>
                                    )}
                                </Box>
                                <Typography>Hex Color</Typography>
                                <Box className={classes.hexInputGroup}>
                                    <TextField
                                        className={classes.hexInput}
                                        name='color'
                                        value={inputFields.hexInput}
                                        id='hexInput'
                                        variant="outlined"
                                        size="small"
                                        error={!hexInputIsValid}
                                        onChange={handleInput}
                                        onBlur={handleChange}
                                    >{template.color}</TextField>
                                    <HexCircle color={template.color}/>
                                </Box>
                            </div>
                        </Box>
                    }
                </Box>

                {editCtaPopupOpen && <EditCtaPopup
                    handleClose={closeEditCtaPopupHandler}
                    actionInputField={actionInputField}
                    open={editCtaPopupOpen}
                    handleActionInput={handleActionInput}
                    handleChange={handleChange}
                    template={template}
                    updateTemplate={updateTemplate}
                ></EditCtaPopup>}
            </Box>
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        actions: lookupSelectors.actionTypesSelector(state),
        fontSizes: lookupSelectors.fontSizeTypesSelector(state),
        titleSpacings: lookupSelectors.titleSpacingTypesSelector(state),
        fontFamilies: lookupSelectors.fontFamilyTypesSelector(state),
        colorPalette: lookupSelectors.colorPaletteSelector(state),
        templateLanguages: lookupSelectors.templateLanguagesSelector(state),
        surveySetType: sharedSelectors.surveySetTypeSelector(state)
    }
}

const mapDispatchToProps = {
    loadLanguages,
    loadTemplateActions,
    loadFontSizes,
    loadTitleSpacings,
    loadFontFamilies,
    loadColorPalette,
    updateTemplate,
    updateTemplateTitleSpacing,
    updateTextTemplate,
    updateTextTemplateTitleColour,
    updateTextTemplateFontSize,
    updateTextTemplateFontFamily,
    updateTextTemplateTitle,
    updateTextTemplateLanguage,
    loadTextSets
};

const CustomizePanelContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizePanel);

export default CustomizePanelContainer;
