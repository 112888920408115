import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Box } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { AgentProfileModel } from '../../SetupPage/SetupModels';
import { ApplicationState } from '../../ApplicationState';

import * as setupSelectors from "../../SetupPage/setupSelectors";
import * as sharedSelectors from "../sharedSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors"
import { getAgents } from "../../SetupPage/setupActions";
import { redirect } from "../router/routerActions";
import { getProfile, signOut, getOrganizationPlan } from "../sharedActions";
import {SubscriptionPlanModel, UserProfileModel} from '../SharedModels';
import { numberWithDivider } from "../../shared/helpers";
import Icon from './Icon';
import UserPhotoIcon from "../../svg/user-photo.svg";
import {loadSubscriptionPlans} from "../../lookups/lookupActions";
import * as selectors from "../../AccountPage/accountSelectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  dropdown: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short
    })
  },
  dropdownOpen: {
    transform: "rotate(-180deg)"
  },
  dropdownClosed: {
    transform: "rotate(0)"
  }
}));

type MenuBarComponentProps = {
  agents: AgentProfileModel[] | [],
  profile: UserProfileModel | undefined,
  isDisabledService: boolean,

  getAgents: typeof getAgents,
  signOut: typeof signOut,
  redirect: typeof redirect,
  isPrimaryOrg: boolean,
  orgPlan?: SubscriptionPlanModel | undefined,
  getOrganizationPlan: typeof getOrganizationPlan
  loadSubscriptionPlans: typeof loadSubscriptionPlans,
  subscriptionPlans: SubscriptionPlanModel[],
  primaryOrgId: number
};

function MenuBarComponent(props: RouteComponentProps<{ orgid: string }> & MenuBarComponentProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { history, location, profile, agents, isDisabledService
          , getAgents, isPrimaryOrg
        } = props;
  const orgIdparam = props.match.params.orgid;

  const activeAgentCount = agents.filter(a => a.active).length;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any, link?: string) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if(link) history.push(link);

    setOpen(false);
  };

  const handleSignout = () => {
    props.signOut({});
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if(!profile) getProfile();
    if(profile && !agents.length) getAgents();
  }, []);

  useEffect(() => {
    props.loadSubscriptionPlans();
    props.getOrganizationPlan()
  }, [])

  const getSubscriptionPlanTitle = (): string | undefined => {
    const orgPlanId = props.orgPlan?.planId;

    for (const plan of props.subscriptionPlans) {
      if (plan.planId === orgPlanId) {
        const currentTitle = plan.title;
        const index = currentTitle.indexOf("(");

        return currentTitle.slice(0, index - 1);
      }
    }
    return undefined;
  }

  const currentPlanTitle = getSubscriptionPlanTitle()
  const isPrimaryOrgPage = profile?.organizationId === props.primaryOrgId;

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          endIcon={<ArrowDropDownIcon className={`${classes.dropdown} ${open ? classes.dropdownOpen : classes.dropdownClosed}`} />}
          onClick={handleToggle}
        >
          {profile?.fullName}
        </Button>

        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition placement="bottom-end" disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem className="user-info">
                      <Box display="flex" flexDirection="column">
                        <Box marginBottom="10px">
                          <Typography color="primary" className="orgname">{profile?.organization.name}</Typography>
                          <Typography>Org ID: {profile?.organizationId && numberWithDivider(profile.organizationId)} {isPrimaryOrg && isPrimaryOrgPage ? "(Master Agency Account)" : null}</Typography>
                          {currentPlanTitle && isPrimaryOrg && isPrimaryOrgPage ? <Typography><strong>Account Plan: </strong>{currentPlanTitle}</Typography> : null}
                          {profile?.organization.agencyLevel && isPrimaryOrg && isPrimaryOrgPage ? <Typography><strong>Agency Plan: </strong>{profile?.organization.agencyLevel}</Typography> : null}
                          {profile?.organization.agencySubAccounts && isPrimaryOrg && isPrimaryOrgPage ? <Typography><strong>Sub-Accounts: </strong>{profile?.organization.agencySubAccounts}</Typography> : null}
                        </Box>
                        <Box display="flex" alignItems="flex-start">
                          <Icon src={UserPhotoIcon} />
                          <Box marginLeft="10px">
                            <Typography color="textPrimary" className="fullName">{profile?.fullName}</Typography>
                            <Typography>User ID: {profile?.id && numberWithDivider(profile.id)}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClose(e, `/${orgIdparam}/account/billinginfo`)}
                      disabled={isDisabledService}
                    >
                      Account
                    </MenuItem>
                    <MenuItem
                      onClick={handleSignout}
                      //disabled={isDisabledService}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  return {
    agents: setupSelectors.agentsSelector(state),
    profile: sharedSelectors.profileSelector(state),
    isDisabledService: sharedSelectors.isDisabledServiceSelector(state),
    orgPlan: sharedSelectors.orgPlanSelector(state),
    subscriptionPlans: lookupSelectors.subscriptionPlansSelector(state),
    primaryOrgId: selectors.primaryOrgIdSelector(state)
  }
}

const mapDispatchToProps = { getAgents, getProfile, redirect, signOut, getOrganizationPlan, loadSubscriptionPlans }

const MenuBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuBarComponent);

export default withRouter(MenuBarContainer);
