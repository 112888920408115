import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import SectionComponent from "../../shared/components/SectionComponent";
import { connect } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import * as s from "../settingsSelectors";
import { getNotificationSettings, editNotificationSettings, addNotificationSettings } from "../settingsActions";
import {getTemplate, getTextTemplate} from "../../SetupPage/setupActions";
import { CSATScale } from "../SettingsModels";
import { appSettings } from '../../config/appSettings';
import editBtnIcon from '../../svg/editbtn.svg';
import Icon from "../../shared/components/Icon";
import {KeyValueModel, OrganizationModel} from "../../shared/SharedModels";
import * as selectors from "../../SetupPage/setupSelectors";
import {TemplateModel, TextTemplateModel} from "../../SetupPage/SetupModels";
import {ratingFilterSelector} from "../../lookups/lookupSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import {IconSetType} from "../../lookups/LookupEnum";
import {loadIconSets} from "../../lookups/lookupActions";
import {AddNotificationSettings} from "../SettingsApiData";
import * as sharedSelectors from "../../shared/sharedSelectors";

const staticDataUrl = appSettings.app.staticDataUrl;

type NotificationSettingsProps = {
    template?: TemplateModel,
    organization: OrganizationModel,
    csatSettings: CSATScale[],
    reviewRatings: KeyValueModel[],
    staticIconSets: KeyValueModel[],
    animatedIconSets: KeyValueModel[],

    addNotificationSettings: typeof addNotificationSettings,
    getNotificationSettings: typeof getNotificationSettings,
    editNotificationSettings: typeof editNotificationSettings,
    getTemplate: typeof getTemplate,
    loadIconSets:  typeof loadIconSets,
    surveySetType?: string,
    textTemplate?: TextTemplateModel,
    getTextTemplate: typeof getTextTemplate
};

type EditPopupState = {
    open: boolean,
    settings?: CSATScale,
};

type EditPopupProps = {
    state: EditPopupState,

    handleClose: () => void,
    edit: typeof editNotificationSettings,
}

const EditPopup: React.FC<EditPopupProps> = (props) => {
    const [editedSettings, setEditedSettings ] = useState<CSATScale | undefined>();
    const [firstInput, setFirstInput] = useState("");
    const [secondInput, setSecondInput] = useState("");
    const [thirdInput, setThirdInput] = useState("");



    useEffect(() => {
        if (props.state.settings) setEditedSettings(props.state.settings);
    }, [props.state.settings]);

    useEffect(() => {
        if (!editedSettings) return;
        const incomingEmails = editedSettings.notificationEmail;
        setFirstInput(incomingEmails);
        const emails = incomingEmails.split(',');
       if (Array.isArray(emails) && emails.length > 0) {
            setFirstInput(emails[0] || "");
            setSecondInput(emails[1] || "");
            setThirdInput(emails[2] || "");
        }
    }, [editedSettings, props.state.settings])

   const handleFirstActionInput = (event: React.ChangeEvent<{ id?: string; value: any }>) => {
        setFirstInput(event.target.value);
    };

    const handleSecondActionInput = (event: React.ChangeEvent<{ id?: string; value: any }>) => {
        setSecondInput(event.target.value);
    };

    const handleThirdActionInput = (event: React.ChangeEvent<{ id?: string; value: any }>) => {
        setThirdInput(event.target.value);
    };

    const handleChange = () => {
        let  checkedEmail = [];
        if (firstInput) {
            checkedEmail.push(firstInput)
        }
        if (secondInput) {
            checkedEmail.push(secondInput)
        }
        if (thirdInput) {
            checkedEmail.push(thirdInput)
        }

        if (editedSettings) {
            setEditedSettings({...editedSettings, notificationEmail: checkedEmail.toString()});
        }
    }

    useEffect(() => {
        handleChange()
    },[firstInput, secondInput, thirdInput])

    const handleSave = async () => {
        if(editedSettings) props.edit(editedSettings);
        props.handleClose();
    }

    return (
        <Dialog
            open={props.state.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">Edit Notification Email Address</DialogTitle>
            <DialogContent dividers={true}>
                <TextField name='notificationEmail' value={firstInput} label="Email" margin={"dense"}
                           variant="outlined" onChange={handleFirstActionInput}  />
                <TextField name='notificationEmail' value={secondInput} label="Email" margin={"dense"}
                           variant="outlined" onChange={handleSecondActionInput} />
                <TextField name='notificationEmail' value={thirdInput} label="Email" margin={"dense"}
                           variant="outlined" onChange={handleThirdActionInput}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSave}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
};

const NotificationSettings = (props: NotificationSettingsProps) => {
    const { organization, csatSettings, getNotificationSettings, reviewRatings, surveySetType } = props;
    const [editPopup, setEditPopup] = useState<EditPopupState>({
        open: false,
    });
    const [iconSet, setIconSet] = useState<any>();

    useEffect(() => {
        if (props.staticIconSets.length === 0) props.loadIconSets();
        if (!props.template) props.getTemplate();
        if (!props.textTemplate) props.getTextTemplate();
    }, []);

    useEffect(() => {
        if (props.staticIconSets.length > 0 && props.animatedIconSets.length > 0) {
            let icons = props.staticIconSets.find(set => props.template ? set.key === props.template.iconSet : false);
            if (!icons) icons = props.animatedIconSets.find(set => props.template ? set.key === props.template.iconSet : false);
            if (icons) {
                setIconSet(icons.value);
            }
        }
    }, [props.template, props.animatedIconSets, props.staticIconSets]);

    const extractEmails = (s: CSATScale) => {
        const emailString = s.notificationEmail;
        const emails = emailString.split(',');
        if (emails.length > 1) {
            return `${emails[0]} +${emails.length - 1}`;
        }
        return `${emails[0]}`;
    }

    useEffect(() => {
        if(!csatSettings.length) getNotificationSettings();
    }, []);

    useEffect(() => {
        getNotificationSettings();
    }, [organization]);


    const editEnableStatusClickHandler = (e: React.ChangeEvent<HTMLInputElement>, scale: CSATScale) => {
        const model: CSATScale = {
            ...scale,
            enabled: e.target.checked,
        };

        props.editNotificationSettings(model);
    };

    const addSetting = (notificationSetting: CSATScale) => {
        const addSetting:AddNotificationSettings = {
            enabled: notificationSetting.enabled,
            emailAddresses: notificationSetting.notificationEmail,
            agentEnabled: notificationSetting.agentEnabled,
            iconPath: notificationSetting.iconPath,
            organizationId: organization.id,
            description: notificationSetting.description,
        };
        props.addNotificationSettings(addSetting);
    };

    const editAgentEnableStatusClickHandler = (e: React.ChangeEvent<HTMLInputElement>, scale: CSATScale) => {
        const model: CSATScale = {
            ...scale,
            agentEnabled: e.target.checked,
        };

        props.editNotificationSettings(model);
    };

    const editClickHandler = (settings: CSATScale) => {
        setEditPopup({
            open: true,
            settings,
        });
    };
    const closeEditPopupHandler = () => {
        setEditPopup({
            open: false,
        });
    };
    const getIconPath = (key: string) => {
        if (!iconSet) return;
        if (key === "VerySatisfied") return iconSet["great"];
        if (key === "ExceededExpectations") return iconSet["exceededExpectation"];
        if (key === "Satisfied") return iconSet["satisfied"];
        if (key === "Dissatisfied") return iconSet["dissatisfied"];
        if (key === "VeryDissatisfied") return iconSet["verydissatisfied"];
    }

    let dynamicIcons = [
        {"key":"VerySatisfied","value":"Very Satisfied"},
        {"key":"ExceededExpectations","value":"Satisfied"},
        {"key":"Satisfied","value":"Neutral"},
        {"key":"Dissatisfied","value":"Dissatisfied"},
        {"key":"VeryDissatisfied","value":"Very Dissatisfied"},
    ];
    if (props.template && props.textTemplate) {
        if (surveySetType === "Images") {
            switch(props.template.actionCount) {
                case 2:
                    dynamicIcons = dynamicIcons.filter((icon) => icon.key.match(/VerySatisfied|VeryDissatisfied/));
                    break;
                case 3:
                    dynamicIcons = dynamicIcons.filter((icon) => icon.key.match(/VerySatisfied|VeryDissatisfied|Satisfied/));
                    break;
            }
        } else if (surveySetType === "Text Only") {
            switch(props.textTemplate.actionCount) {
                case 2:
                    dynamicIcons = dynamicIcons.filter((icon) => icon.key.match(/VerySatisfied|VeryDissatisfied/));
                    break;
                case 3:
                    dynamicIcons = dynamicIcons.filter((icon) => icon.key.match(/VerySatisfied|VeryDissatisfied|Satisfied/));
                    break;
            }
        }
    }
    const defaultSetting: CSATScale = {
        id: 0,
        surveyId: 0,
        rate: 0,
        enabled: false,
        notificationEmail: "",
        iconPath: "",
        description: "",
        agentEnabled: false,
    };

    return (
        <Box className="email-notification">
            <SectionComponent marginTop="42px" width="558px" >EMAIL ALERT NOTIFICATION</SectionComponent>
            <Box className='content-block table'>
                <Box className="title">
                    <Typography>Who should be emailed the Customer Feedback Alert as it comes in?</Typography>
                </Box>

                <TableContainer>
                    <Table className="tbl" size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"/>
                                <TableCell align="center">Feedback</TableCell>
                                <TableCell align="center">Enabled</TableCell>
                                <TableCell align="center">Notification Email Address</TableCell>
                                <TableCell align="center"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dynamicIcons.length > 0 && dynamicIcons.filter(rating => rating.key !== "Archived").map((s) => {
                                const setting = csatSettings.find(cs => cs.description === s.key) ? csatSettings.find(cs => cs.description === s.key) : defaultSetting;
                                const iconPath = getIconPath(s.key);
                                return (
                                    <TableRow key={`notification_${s.value}`}>
                                        <TableCell>
                                            <img src={surveySetType === 'Images' ? `${staticDataUrl}${iconPath}` : `${staticDataUrl}/default/f${s.value.replace(" ","")}.png`} alt={s.key} style={{width: "35px"}} />
                                        </TableCell>
                                        <TableCell>{s.value}</TableCell>
                                        <TableCell>
                                            <Checkbox id={`notify-feedback-${setting!.id}`} checked={setting && setting.enabled}
                                                  color="primary"
                                                  onChange={(e) => {
                                                      if (setting && setting.id > 0) {
                                                          editEnableStatusClickHandler(e, setting);
                                                      } else {
                                                          addSetting({
                                                              ...defaultSetting,
                                                              enabled: e.target.checked,
                                                              description: s.key,
                                                              iconPath
                                                          });
                                                      }
                                                  }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography color="primary">{setting ? extractEmails(setting) : ""}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="outlined" color="primary"
                                                    endIcon={<Icon src={editBtnIcon}></Icon>}
                                                    onClick={() => setting && editClickHandler(setting)}>Edit</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            { editPopup.open && <EditPopup handleClose={closeEditPopupHandler} edit={props.editNotificationSettings} state={editPopup} ></EditPopup> }

            <Box style={{marginTop: "15px"}} className='content-block table'>
                <Box className="title">
                    <Typography>Do you want your Staff Members to receive a copy of the notifications as they come in?</Typography>
                </Box>

                <TableContainer>
                    <Table className="tbl" size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"/>
                                <TableCell align="center">Feedback</TableCell>
                                <TableCell align="center">Enabled</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dynamicIcons.length > 0 && dynamicIcons.filter(rating => rating.key !== "Archived").map((s) => {
                                const setting = csatSettings.find(cs => cs.description === s.key) ? csatSettings.find(cs => cs.description === s.key) : defaultSetting;
                                const iconPath = getIconPath(s.key);
                                return (
                                    <TableRow key={`agent_notification_${s.key}`}>
                                        <TableCell>
                                            <img src={surveySetType === 'Images' ? `${staticDataUrl}${iconPath}` : `${staticDataUrl}/default/f${s.value.replace(" ","")}.png`} alt="Icon" style={{width: "35px"}}/>
                                        </TableCell>
                                        <TableCell>{s.value}</TableCell>
                                        <TableCell>
                                            <Checkbox id={`notify-staff-${setting!.id}`} checked={Boolean(setting!.agentEnabled)}
                                                      color="primary"
                                                      onChange={(e) => editAgentEnableStatusClickHandler(e, setting!)}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        csatSettings: s.csatSettingsSelector(state),
        template: selectors.templateSelector(state),
        reviewRatings: ratingFilterSelector(state),
        staticIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Static),
        animatedIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Animated),
        surveySetType: sharedSelectors.surveySetTypeSelector(state),
        textTemplate: selectors.textTemplateSelector(state)
    }
}

const mapDispatchToProps = {
    addNotificationSettings,
    getNotificationSettings,
    editNotificationSettings,
    getTemplate,
    loadIconSets,
    getTextTemplate
};

const NotificationSettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationSettings);

export default NotificationSettingsContainer;
