import {getCurrentDomainName} from "../shared/helpers";

const domain = getCurrentDomainName();

let appSettings = {};
let oauthSettings = {};

const NODE_ENV = process.env.REACT_APP_DEPLOY_ENV || "production";

const SURVEY_SET_TYPES = {
    1: 'Images',
    2: 'Gif',
    3: 'Text Only',
};

if (NODE_ENV === "local_api") {
    appSettings.app = {
        name: 'RateMyServiceApp',
        description: 'RateMyService',
        appPath: '',
        staticDataUrl: "https://assets-hhcvacdycda3gfht.z01.azurefd.net/staging",
    }

    appSettings.sites = {
        main: {
            baseAddress: 'localhost:3000'
        },
        api: {
            baseAddress: 'localhost:7145'
        },
        authorization: {
            baseAddress: 'localhost:7145'
        },
        reviews: {
            baseAddress: 'localhost:7145',
        }
    };

    appSettings.authorizationServer = {
        baseAddress: 'http://' + appSettings.sites.authorization.baseAddress, // 'http' instead of 'https'
        authorizationEndpoint: '/auth',
        scope: 'internal',
    };

    appSettings.clientApp = {
        baseAddress: 'http://' + appSettings.sites.main.baseAddress,
        appUrl: 'http://' + appSettings.sites.main.baseAddress + appSettings.app.appPath,
        clientId: appSettings.app.name,
        // need to keep it here ??
        clientSecret: '',
    };
    appSettings.clientApp.callbackUrl = appSettings.clientApp.appUrl + '/oauth/callback';

    appSettings.apisMetadata = [
        {
            id: 'rms',
            name: 'api',
            baseAddress: 'http://' + appSettings.sites.api.baseAddress,
            scope: 'internal'
        },
    ];

    appSettings.reviewsApp = {
        baseAddress: 'http://' + appSettings.sites.reviews.baseAddress,
        submitReviewUrl: 'http://' + appSettings.sites.reviews.baseAddress + '/submit-review'
    }

    appSettings.Stripe = {
        publishableKey: "pk_test_51LkQCrCi9UDYBWn6YPoUfUliHOyJH695WvTBzv7aG0KMsZKguhapXBJVFVjk50FGF43kVIFUeZLweWXrRB4b3Ku100phSYgT67",
    };

    appSettings.constants = {
        subscriptionTrialDays: 30,
        surveySetTypes: SURVEY_SET_TYPES,
    };

    oauthSettings.google = {
        REDIRECT_URI: "http://localhost:3000/oauthcallback",
        SCOPE: "https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/userinfo.email",
    };

    oauthSettings.azure = {
        REDIRECT_URI: "http://localhost:3000/oauthcallback",
        SCOPE: "User.Read.All",
    };

    appSettings.reCaptchaSiteKey = "6LdmgCEpAAAAADsReai3cOTtdce4Wo5FIwIFIkFV";
}
else if (NODE_ENV === "local") {
    appSettings.app = {
        name: 'RateMyServiceApp',
        description: 'RateMyService',
        appPath: '',
        staticDataUrl: "https://assets-hhcvacdycda3gfht.z01.azurefd.net/staging",
    }

    appSettings.sites = {
        main: {
            baseAddress: 'localhost:3000'
        },
        api: {
            baseAddress: 'api.staging.ratemyservice.io',
        },
        authorization: {
            baseAddress: 'api.staging.ratemyservice.io',
        },
        reviews: {
            baseAddress: 'reviews-staging.ratemyservice.io',
        },
    };

    appSettings.authorizationServer = {
        baseAddress: 'https://' + appSettings.sites.authorization.baseAddress,
        authorizationEndpoint: '/auth',
        scope: 'internal',
    };

    appSettings.clientApp = {
        baseAddress: 'http://' + appSettings.sites.main.baseAddress,
        appUrl: 'https://' + appSettings.sites.main.baseAddress + appSettings.app.appPath,
        clientId: appSettings.app.name,
        // need to keep it here ??
        clientSecret: '',
    };
    appSettings.clientApp.callbackUrl = appSettings.clientApp.appUrl + '/oauth/callback';

    appSettings.apisMetadata = [
        {
            id: 'rms',
            name: 'api',
            baseAddress: 'https://' + appSettings.sites.api.baseAddress,
            scope: 'internal'
        },
    ];

    appSettings.reviewsApp = {
        baseAddress: 'https://' + appSettings.sites.reviews.baseAddress,
        submitReviewUrl: 'https://' + appSettings.sites.reviews.baseAddress + '/submit-review'
    }

    appSettings.Stripe = {
        publishableKey: "pk_test_51LkQCrCi9UDYBWn6YPoUfUliHOyJH695WvTBzv7aG0KMsZKguhapXBJVFVjk50FGF43kVIFUeZLweWXrRB4b3Ku100phSYgT67",
    };

    appSettings.constants = {
        subscriptionTrialDays: 30,
        surveySetTypes: SURVEY_SET_TYPES,
    }

    oauthSettings.google = {
        REDIRECT_URI: "https://app.staging.ratemyservice.io/oauthcallback",
        SCOPE: "https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/userinfo.email",
    };

    oauthSettings.azure = {
        REDIRECT_URI: "https://app.staging.ratemyservice.io/oauthcallback",
        SCOPE: "User.Read.All",
    };

    appSettings.reCaptchaSiteKey = "6LdqSA8nAAAAAFN18GMPxrV0Fal_I7m0w8XZUzci";
}
else if (NODE_ENV === "staging") {

    appSettings.app = {
        name: 'RateMyServiceApp',
        description: 'RateMyService',
        appPath: '',
        staticDataUrl: "https://assets-hhcvacdycda3gfht.z01.azurefd.net/staging",
    }
    if (domain.includes('ratemyservice.io')) {
        appSettings.sites = {
            main: {
                baseAddress: 'app.staging.ratemyservice.io'
            },
            api: {
                baseAddress: 'api.staging.ratemyservice.io',
            },
            authorization: {
                baseAddress: 'api.staging.ratemyservice.io',
            },
            reviews: {
                baseAddress: 'reviews-staging.ratemyservice.io',
            }
        };
    } else {
        appSettings.sites = {
            main: {
                baseAddress: 'app.staging.' + domain,
            },
            api: {
                baseAddress: 'rms-api.staging.' + domain,
            },
            authorization: {
                baseAddress: 'rms-api.staging.' + domain,
            },
            reviews: {
                baseAddress: 'rms-reviews.staging.' + domain,
            }
        };
    }

    appSettings.authorizationServer = {
        baseAddress: 'https://' + appSettings.sites.authorization.baseAddress,
        authorizationEndpoint: '/auth',
        scope: 'internal',
    };

    appSettings.clientApp = {
        baseAddress: 'https://' + appSettings.sites.main.baseAddress,
        appUrl: 'https://' + appSettings.sites.main.baseAddress + appSettings.app.appPath,
        clientId: appSettings.app.name,
        // need to keep it here ??
        clientSecret: '',
    };
    appSettings.clientApp.callbackUrl = appSettings.clientApp.appUrl + '/oauth/callback';

    appSettings.apisMetadata = [
        {
            id: 'rms',
            name: 'api',
            baseAddress: 'https://' + appSettings.sites.api.baseAddress,
            scope: 'internal'
        },
    ];

    appSettings.reviewsApp = {
        baseAddress: 'https://' + appSettings.sites.reviews.baseAddress,
        submitReviewUrl: 'https://' + appSettings.sites.reviews.baseAddress + '/submit-review'
    }

    appSettings.Stripe = {
        publishableKey: "pk_test_51LkQCrCi9UDYBWn6YPoUfUliHOyJH695WvTBzv7aG0KMsZKguhapXBJVFVjk50FGF43kVIFUeZLweWXrRB4b3Ku100phSYgT67",
    };

    appSettings.constants = {
        subscriptionTrialDays: 30,
        surveySetTypes: SURVEY_SET_TYPES,
    };

    oauthSettings.google = {
        REDIRECT_URI: "https://app.staging.ratemyservice.io/oauthcallback",
        SCOPE: "https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/userinfo.email",
    };

    oauthSettings.azure = {
        REDIRECT_URI: "https://app.staging.ratemyservice.io/oauthcallback",
        SCOPE: "User.Read.All",
    };

    appSettings.reCaptchaSiteKey = "6LdqSA8nAAAAAFN18GMPxrV0Fal_I7m0w8XZUzci";
}
else if (NODE_ENV === "production") {
    appSettings.app = {
        name: 'RateMyServiceApp',
        description: 'RateMyService',
        appPath: '',
        staticDataUrl: "https://assets-hhcvacdycda3gfht.z01.azurefd.net/static",
    }

    if (domain.includes('ratemyservice.io')) {
        appSettings.sites = {
            main: {
                baseAddress: 'app.ratemyservice.io',
            },
            api: {
                baseAddress: 'api.ratemyservice.io',
            },
            authorization: {
                baseAddress: 'api.ratemyservice.io',
            },
            reviews: {
                baseAddress: 'reviews.ratemyservice.io',
            },
        };
    } else {
        appSettings.sites = {
            main: {
                baseAddress: 'rate.' + domain,
            },
            api: {
                baseAddress: 'rate-api.' + domain,
            },
            authorization: {
                baseAddress: 'rate-api.' + domain,
            },
            reviews: {
                baseAddress: 'reviews.' + domain,
            },
        };
    }
    appSettings.authorizationServer = {
        baseAddress: 'https://' + appSettings.sites.authorization.baseAddress,
        authorizationEndpoint: '/auth',
        scope: 'internal',
    };

    appSettings.clientApp = {
        baseAddress: 'https://' + appSettings.sites.main.baseAddress,
        appUrl: 'https://' + appSettings.sites.main.baseAddress + appSettings.app.appPath,
        clientId: appSettings.app.name,
        // need to keep it here ??
        clientSecret: '',
    };
    appSettings.clientApp.callbackUrl = appSettings.clientApp.appUrl + '/oauth/callback';

    appSettings.apisMetadata = [
        {
            id: 'rms',
            name: 'api',
            baseAddress: 'https://' + appSettings.sites.api.baseAddress,
            scope: 'internal'
        },
    ];

    appSettings.reviewsApp = {
        baseAddress: 'https://' + appSettings.sites.reviews.baseAddress,
        submitReviewUrl: 'https://' + appSettings.sites.reviews.baseAddress + '/submit-review'
    }

    appSettings.Stripe = {
        publishableKey: "pk_live_51LkQCrCi9UDYBWn6ltfPzqY6ALQkWsoblmg2trFUGXdF0paApEhIuejWNLB65AtRSD1eObjOH0FLbLaLacw7mhA100PuX8e9t4",
    };

    appSettings.constants = {
        subscriptionTrialDays: 30,
        surveySetTypes: SURVEY_SET_TYPES,
    };

    oauthSettings.google = {
        REDIRECT_URI: "https://app.ratemyservice.io/oauthcallback",
        SCOPE: "https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/userinfo.email",
    };

    oauthSettings.azure = {
        REDIRECT_URI: "https://app.ratemyservice.io/oauthcallback",
        SCOPE: "User.Read.All",
    };

    appSettings.reCaptchaSiteKey = "6Lf0RQ8nAAAAAFGSPZtnDzJWoZPpkwm47FXAGHE_";
}

export {appSettings, oauthSettings};
